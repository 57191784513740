import { IconButton, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useInsightsPanelHeaderNavigation } from './useInsightsPanelHeaderNavigation';
import { useGetDocumentByIdQuery } from 'hooks/useGetDocumentByIdQuery';
import { Tooltip } from '@mui/material';
import IcoEdit from 'components/icon/icons/IcoEdit';

interface InsightsHeaderEditButtonProps {
  documentId: string;
  isExpanded: boolean;
}

export function InsightsHeaderEditButton({ documentId, isExpanded }: InsightsHeaderEditButtonProps) {
  const { t } = useTranslation();
  const { navigateToEditor } = useInsightsPanelHeaderNavigation(documentId);
  const { data: document } = useGetDocumentByIdQuery(documentId);
  const isLocked = document?.isLocked;

  const IconButtonComponent = isExpanded ? ExpandedIconButton : ShrunkIconButton;

  return (
    <Tooltip title={isLocked ? t('pipeline.insights.edit_locked_tooltip') : t('pipeline.insights.edit_tooltip')}>
      <span>
        <IconButtonComponent
          disabled={isLocked}
          onClick={navigateToEditor}
          data-testid="insights-edit-button"
          sx={{
            backgroundColor: 'primary.main',
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#001118',
              color: '#FFFFFF',
            },
            '&.Mui-disabled': {
              backgroundColor: '#00283A1F',
              color: '#00283A61',
            },
          }}
        >
          <IcoEdit />
          {isExpanded && <Typography variant="labelMedium">{t('edit')}</Typography>}
        </IconButtonComponent>
      </span>
    </Tooltip>
  );
}

const ShrunkIconButton = styled(IconButton)({
  padding: '0.3125rem',
});

const ExpandedIconButton = styled(IconButton)({
  borderRadius: '6.25rem',
  padding: '0.375rem 1rem',
  gap: '0.5rem',
  '& svg': {
    width: '1.25rem',
    height: '1.25rem',
  },
});
