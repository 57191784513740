import { createContext, useContext } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Activity, ActivityDto } from './types';
import { useQueryActivities } from './useQueryActivities';

type ActivitiesContext = Activity[];

export const ActivitiesContext = createContext<ActivitiesContext | null>(null);
export const useActivitiesContext = () => {
  const context = useContext<ActivitiesContext | null>(ActivitiesContext);

  if (!context) {
    throw new Error('useActivitiesContext must be used within an ActivitiesProvider');
  }

  return context;
};

export const ActivitiesProvider = ({ children, documentId }: { children: React.ReactNode; documentId: string }) => {
  const { result } = useQueryActivities({ documentId });
  const { t } = useTranslation();

  const value = result.map((activity, index) => ({
    id: index,
    type: activity.type,
    title: getActivityTitle(activity, t),
    details: getActivityDetails(activity),
    date: new Date(activity.createdAt).toISOString(),
    isViewedByMultipleUsers: false,
    who: 'user' in activity ? [activity.user.name] : [],
  }));

  return <ActivitiesContext.Provider value={value}>{children}</ActivitiesContext.Provider>;
};

function getActivityTitle(activity: ActivityDto, t: TFunction) {
  switch (activity.type) {
    case 'document-created':
      return t('pipeline.insights.document_created_title', { user: activity.user.name });
    case 'document-won':
      return t('pipeline.insights.document_won_title');
    case 'document-lost':
      return t('pipeline.insights.document_lost_title');
    case 'document-sent':
      return t('pipeline.insights.document_sent_title');
    case 'document-trashed':
      return t('pipeline.insights.document_trashed_title');
    default:
      return '';
  }
}

function getActivityDetails(activity: ActivityDto) {
  switch (activity.type) {
    default:
      return undefined;
  }
}
